import { StatusType, Eligibility } from '../../../types'

export const mapEligibilityStatus = (
  eligibility: Eligibility | null
): StatusType.Pending | StatusType.Approved | StatusType.Rejected | StatusType.CannnotDefine => {
  if (eligibility?.status === 'live' && eligibility?.results?.eligibilityStatus === 'pass') {
    return StatusType.Approved
  }

  if (eligibility?.results?.eligibilityStatus === 'fail' || eligibility?.status === 'validation_request_failed') {
    return StatusType.Rejected
  }

  if (eligibility?.status === 'cannot_define_eligibility') {
    return StatusType.CannnotDefine
  }

  return StatusType.Pending
}
