import { Badge, BadgeVariant, IconRegistry } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { StatusType } from '../../../types'

export const statusIconMap: {
  [key in StatusType | string]: {
    status: BadgeVariant
    icon: keyof typeof IconRegistry
    text: string
  }
} = {
  [StatusType.Pending]: {
    status: 'default',
    icon: 'clock',
    text: 'status.pending'
  },
  [StatusType.Approved]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.approved'
  },
  [StatusType.Rejected]: {
    status: 'critical',
    icon: 'reject',
    text: 'status.rejected'
  },
  [StatusType.CannnotDefine]: {
    status: 'critical',
    icon: 'reject',
    text: 'status.rejected'
  }
}

export function StatusBadge({ statusType }: Readonly<{ statusType: StatusType }>) {
  const { t } = useTranslation()

  return (
    <Badge variant={statusIconMap[statusType].status} icon={statusIconMap[statusType].icon}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {t(statusIconMap[statusType].text as any)}
    </Badge>
  )
}
