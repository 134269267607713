import { AxiosInstance } from 'axios'

import { baseUrl } from '@percent/partner-dashboard/services/apiPaths'
import { PBPListResponse, PBPResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import {
  GetValidationSubmissionsProps,
  GetValidationSubmissionDocumentsProps,
  ValidationSubmission,
  ValidationSubmissionDocument,
  ValidationSubmissionConfigurations,
  ValidationSubmissionOrganisationNameSearchResults
} from './applicationsService.types'
import { throttleAsync } from '@percent/utility'
import { SingleValidationSubmission } from '@percent/domain/verifications'

type ValidationsServiceCapabilities = {
  percentClient: AxiosInstance
}

export const applicationsService = ({ percentClient }: ValidationsServiceCapabilities) => ({
  getValidationSubmissions: (params: GetValidationSubmissionsProps) =>
    percentClient.get<PBPListResponse<ValidationSubmission>>(`${baseUrl}validation-submissions`, { params }),
  getValidationSubmission: ({ id }: { id: string }) =>
    percentClient.get<PBPResponse<SingleValidationSubmission>>(`${baseUrl}validation-submissions/${id}`),
  getValidationSubmissionDocuments: ({ id }: GetValidationSubmissionDocumentsProps) =>
    percentClient.get<PBPListResponse<ValidationSubmissionDocument>>(
      `${baseUrl}validation-submissions/${id}/documents`
    ),
  getValidationSubmissionConfigurations: () =>
    percentClient.get<PBPResponse<ValidationSubmissionConfigurations>>(
      `${baseUrl}validation-submission-configurations`
    ),
  getValidationSubmissionIdsByOrganisationName: throttleAsync(
    (params: { query?: string; validationRequestIds?: string[]; organisationIds?: string[] }) =>
      percentClient.get<PBPResponse<ValidationSubmissionOrganisationNameSearchResults>>(
        `${baseUrl}validation-submissions-organisations`,
        { params }
      ),
    1000,
    { leading: false, trailing: true }
  )
})
