import React from 'react'
import { useLocation, Link, Redirect } from 'react-router-dom'
import { AppBar, Breadcrumbs, Grid, MenuItem, Toolbar, Menu, Typography } from '@material-ui/core'
import classNames from 'classnames/bind'

import { useBreadcrumbsData } from '../breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'

import { Icon } from '@percent/lemonade'
import { ReactComponent as NavigateNextIcon } from '@percent/partner-dashboard/common/assets/images/navigate-next-icon.svg'
import { useAuthState, useAuthDispatch } from '@percent/partner-dashboard/common/hooks'
import styles from './TopBar.module.scss'
import { ReactComponent as DropdownSVG } from '@percent/partner-dashboard/common/assets/images/dropdown.svg'
import { replaceDashWithEmptySpace } from '@percent/partner-dashboard/common/library/utility/utility'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { SET_LOGOUT } from '@percent/partner-dashboard/context/auth'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

export function TopBar() {
  const { t } = useTranslation()
  const dispatch = useAuthDispatch()
  const { authService } = useServices()
  const { path: locationPath } = useBreadcrumbsData()
  const { user } = useAuthState()
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    authService.logout().then(() => {
      localStorage.removeItem('authState')
      dispatch({
        type: SET_LOGOUT
      })
    })

    return <Redirect to="/signin" />
  }

  const paths = pathname.split('/') as string[]

  const mappedPathsClassic = (() => {
    if (paths.length > 2 && paths[1].includes('-') && locationPath) {
      return ['', replaceDashWithEmptySpace(paths[1]), locationPath]
    } else if (paths.length > 2 && paths[1] === 'workplace-giving') {
      return ['', paths[2]]
    } else if (paths[1].includes('-')) {
      return ['', replaceDashWithEmptySpace(paths[1])]
    } else if (paths.length > 2 && paths[1] === 'applications' && locationPath) {
      return ['', 'applications', locationPath]
    } else {
      return paths
    }
  })()

  const mappedPathsNew = (() => {
    let uri = ''
    const results: { url?: string; name: string }[] = []

    paths.slice(1).forEach(part => {
      uri = `${uri}/${part}`

      let name = replaceDashWithEmptySpace(part)
      name = name.includes('_') ? name : name.replace(/\b\w/g, l => l.toUpperCase())

      results.push({ url: uri, name })
    })

    if (locationPath && results[results.length - 1].name !== locationPath) {
      results.push({ url: undefined, name: locationPath })
    }

    return results
  })()

  const shouldUseNewBreadcrumbs = ['workplace-giving'].includes(paths[1])

  return (
    <AppBar position="static">
      <Toolbar className={styles.toolBar}>
        <Breadcrumbs className={styles.breadcrumbs} separator={<NavigateNextIcon />} aria-label="breadcrumb">
          {!shouldUseNewBreadcrumbs &&
            mappedPathsClassic.map(
              (path, id) =>
                path && (
                  <Link
                    className={cx({
                      breadcrumbActive: id === mappedPathsClassic.length - 1,
                      breadcrumb: id !== mappedPathsClassic.length - 1,
                      'link--disabled': id === mappedPathsClassic.length - 1
                    })}
                    key={path}
                    to={`/${path.replace(' ', '-')}`}
                  >
                    {path.includes('_') ? path : path.replace(/\b\w/g, l => l.toUpperCase())}
                  </Link>
                )
            )}
          {shouldUseNewBreadcrumbs &&
            mappedPathsNew.map((breadcrumb, id) => (
              <Link
                className={cx({
                  breadcrumbActive: id === mappedPathsNew.length - 1,
                  breadcrumb: breadcrumb.url !== undefined,
                  'link--disabled': breadcrumb.url === undefined
                })}
                key={breadcrumb.name}
                to={breadcrumb.url}
              >
                {breadcrumb.name}
              </Link>
            ))}
        </Breadcrumbs>
        <Grid direction="row-reverse" container>
          <MenuItem onClick={handleClick} className={styles.menuItem}>
            <div className={styles.userDetail}>
              <Typography className={styles.nameText} variant="body1">
                {user?.fullName ?? user?.preferredName ?? user?.email}
              </Typography>
              <Typography className={styles.roleText} variant="body2" data-testid="nav-user-role">
                {user?.role?.displayName}
              </Typography>
            </div>
            <DropdownSVG className={anchorEl ? styles.dropdown : undefined} />
          </MenuItem>
          <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={styles.userProfileMenu}
            >
              <Link to="/my-account">
                <MenuItem className={styles.userProfileMenuItem}>
                  <Icon name="user-circle" size={6} color="gray500" />
                  {t('menu.accountSettings')}
                </MenuItem>
              </Link>
              <Link to="/users">
                <MenuItem className={styles.userProfileMenuItem}>
                  <Icon name="users" size={6} color="gray500" />
                  {t('menu.myTeam')}
                </MenuItem>
              </Link>
              <MenuItem className={styles.userProfileMenuItem} onClick={handleLogout}>
                <Icon name="log-out" size={6} color="gray500" />
                {t('menu.logout')}
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
