import { Trans, useTranslation } from 'react-i18next'

import { ActionModal, Modal } from '@percent/lemonade'
import { CancelOpportunityModalProps } from './CancelOpportunityModal.types'

export function CancelOpportunityModal({
  open,
  handleClose,
  handleCancelOpportunity,
  isLoading,
  opportunityName
}: Readonly<CancelOpportunityModalProps>) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={handleClose}>
      <ActionModal
        title={t('typography.volunteering.cancelOpportunity.title')}
        primaryButtonText={t('typography.yesCancel')}
        secondaryButtonText={t('typography.keepOpportunity')}
        handleClose={handleClose}
        handleSubmit={handleCancelOpportunity}
        loading={isLoading}
      >
        <Trans
          i18nKey="typography.volunteering.cancelOpportunity.description"
          components={{ b: <b /> }}
          values={{ opportunityName }}
        />
      </ActionModal>
    </Modal>
  )
}
