import { StatusType } from '../../../types'
import { PrimitiveType } from 'intl-messageformat'

export const formatStatusBannerMessage = ({
  overallStatus,
  agentEmail,
  configurationName,
  t
}: {
  overallStatus: StatusType.Approved | StatusType.Rejected | StatusType.Pending
  agentEmail: string | undefined
  configurationName: string | null
  t: (descriptor: string, values?: Record<string, PrimitiveType>) => string
}) => {
  if (agentEmail && configurationName) {
    return t(`typography.application.${overallStatus}.withAgentEmailAndConfigName`, { agentEmail, configurationName })
  }

  if (configurationName) {
    return t(`typography.application.${overallStatus}.withConfigName`, { configurationName })
  }

  return t(`typography.application.${overallStatus}.shortened`)
}
