import { environment } from '@percent/partner-dashboard/config'
import { environments } from '@percent/partner-dashboard/constants/environments'

const getSplitKey = () => {
  if ([environments.PRODUCTION].includes(environment as string)) {
    return process.env.NX_SPLIT_PRODUCTION
  }

  if ([environments.SANDBOX].includes(environment as string)) {
    return process.env.NX_SPLIT_SANDBOX
  }

  if ([environments.PREPROD].includes(environment as string)) {
    return process.env.NX_SPLIT_PREPROD
  }

  if ([environments.STAGING, environments.QA].includes(environment as string)) {
    return process.env.NX_SPLIT_STAGING
  }

  if (typeof environment === 'undefined') {
    return 'testing'
  }

  return 'localhost'
}

/*
  Split SDK Config should reflect whats on the split.io production feature flag values,
  the above splitSdkConfig features values are set as default values for feature flags
  when the split.io has timedout.
*/
export const features = {
  userRolesInviteButtonFeatureFlag: {
    key: 'pd_user_roles_invite_button',
    defaultValue: 'off'
  },
  orgNameSearchFeatureFlag: {
    key: 'pd_org_name_search',
    defaultValue: 'off'
  },
  countryCodeFilteringFeatureFlag: {
    key: 'pd_country_code_filtering',
    defaultValue: 'off'
  },
  featuredFundraiserFlag: {
    key: 'pd_featured_fundraiser',
    defaultValue: 'off'
  },
  featuredVolunteeringFlag: {
    key: 'pd_featured_volunteering',
    defaultValue: 'off'
  }
}

export const featureNames = Object.keys(features) as (keyof typeof features)[]

export const featureKeys = Object.values(features).map(({ key }) => key)

export type Feature = typeof featureNames[0]

export const splitSdkConfig = {
  core: {
    authorizationKey: getSplitKey(),
    key: 'key'
  },
  features: featureNames.reduce<Record<string, string>>((acc, v) => {
    acc[features[v].key] = features[v].defaultValue

    return acc
  }, {}),
  scheduler: {
    offlineRefreshRate: 15
  }
}
