import styled from 'styled-components'
import { Badge, Button, Heading, Text, Card, Icon } from '@percent/lemonade'
import { FeaturedCardProps } from './featured-card.types'
import { OrganisationNameAndFlag } from '../organisation-name-and-flag/organisation-name-and-flag'
import { Skeleton } from '@mui/material'
import dayjs from 'dayjs'

const FeaturedCardContent = styled.div`
  ${({ theme }) =>
    `
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      width: 58%;
      padding: ${theme.sizes[6]};

      @media only screen and (max-width: 767px) {
        width: 100%;
        background: linear-gradient(
          81deg,
          rgba(255, 255, 255, 0.773546918767507) 64%,
          rgba(139, 74, 255, 0.12648809523809523) 100%
        );
        border-radius: ${theme.sizes[2]};
      }
    `}

  h6 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 72px;
  }
`

const FeaturedCardImage = styled.div<{ imgSrc: string }>`
  ${({ imgSrc, theme }) =>
    `
      width: 42%;
      background-image: url(${imgSrc});;
      background-size: cover;
      background-position: center;
      border-top-right-radius: ${theme.sizes[2]};
      border-bottom-right-radius: ${theme.sizes[2]};
    `}
  @media only screen and (max-width: 767px) {
    width: 0;
    background-image: none;
  }
`

const FeaturedOrgName = styled.div`
  ${({ theme }) =>
    `
      font-size: 18px;
      font-weight: 600;
      line-height: ${theme.sizes[6]};

      span {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        align-items: center;
      }
    `}
`

const FeaturedDateDetails = styled.div`
  ${({ theme }) =>
    `
    & > div {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: ${theme.sizes[2]};
    }

    & p {
      color: ${theme.colors.gray600};
    }
`}
`

const FeaturedCardDescription = styled.div`
  ${({ theme }) =>
    `
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    font-size: ${theme.fontSizes.bodySmall};
    line-height: ${theme.sizes[6]};
    letter-spacing: 0.013rem;
    height: 96px;
`}
`

const FeaturedCardButtonInfo = styled.div`
  display: flex;
  align-items: center;

  button {
    ${({ theme }) => `
      margin-right: ${theme.sizes.xs};
    `}
  }
`

export const FeaturedCardSkeleton = () => (
  <Card>
    <FeaturedCardContent>
      <Skeleton variant="rectangular" width={100} style={{ borderRadius: 4 }} />
      <Skeleton variant="rectangular" width="100%" height={66} />
      <Skeleton variant="rectangular" width="100%" height={100} />
      <div>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={22}
          style={{ marginBottom: 2 }}
        />
        <Skeleton variant="rectangular" width="100%" height={24} />
      </div>
      <Skeleton
        variant="rectangular"
        width={92}
        height={32}
        style={{ borderRadius: 4 }}
      />
    </FeaturedCardContent>
    <Skeleton variant="rectangular" width="42%" height="auto" />
  </Card>
)

export const FeaturedCard = (props: Readonly<FeaturedCardProps>) => {
  const {
    type,
    title,
    description,
    badgeText,
    descriptionText,
    buttonText,
    organisationName,
    countryCode,
    actionButton,
    imgSrc,
    cardButtonInfo,
  } = props
  return (
    <Card>
      <FeaturedCardContent>
        <Badge variant="informative">
          <Text as="p" size="small">
            {badgeText}
          </Text>
        </Badge>
        <Heading data-testid="featured-title" level="h6">
          {title}
        </Heading>
        <FeaturedCardDescription>{description}</FeaturedCardDescription>
        {type === 'featured_opportunity' ? (
          props.timeTracking === 'manual' || !props.startDate ? null : (
            <FeaturedDateDetails>
              <div>
                <Icon name="calendar" size="m" color="neutral300" />
                <Text as="p" size="small" fontWeight="medium">
                  {props.startDate.date}
                </Text>
              </div>
              <div>
                <Icon name="clock" size="m" color="neutral300" />
                <Text as="p" size="small" fontWeight="medium">
                  {props.startDate.time}
                </Text>
              </div>
            </FeaturedDateDetails>
          )
        ) : (
          <FeaturedOrgName>
            <Text size="small">{descriptionText}</Text>
            <OrganisationNameAndFlag
              name={organisationName}
              countryCode={countryCode}
            />
          </FeaturedOrgName>
        )}
        <FeaturedCardButtonInfo>
          <Button onPress={actionButton} size="small">
            {buttonText}
          </Button>
          {cardButtonInfo}
        </FeaturedCardButtonInfo>
      </FeaturedCardContent>
      {imgSrc && <FeaturedCardImage imgSrc={imgSrc} />}
    </Card>
  )
}
