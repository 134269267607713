import { getApiDomainFromHostname } from '@percent/utility'
import { environments } from './constants/environments'

export const environment = process.env.NX_REACT_APP_ENV

const envConfig = (() => {
  const appDomain = getApiDomainFromHostname()
  const obj = {
    urls: {
      api: '',
      app: '',
      fundraiserUrl: ''
    },
    keys: {
      ga: ''
    }
  }

  switch (environment) {
    case 'Test':
    case environments.LOCAL_SERVER:
      obj.urls.api = 'http://localhost:8080/'
      obj.urls.app = 'https://localhost:3000'
      obj.urls.fundraiserUrl = 'https://localhost:3000/fundraisers'
      obj.keys.ga = ''
      break
    case environments.LOCAL:
      obj.urls.api = `https://staging-api.${appDomain}/`
      obj.urls.app = 'https://localhost:3000'
      obj.urls.fundraiserUrl = `https://staging-giving.${appDomain}/fundraisers`
      obj.keys.ga = ''
      break
    case environments.QA:
      obj.urls.api = `https://qa-api.${appDomain}/`
      obj.urls.app = `https://qa-sandbox.${appDomain}`
      obj.urls.fundraiserUrl = `https://qa-giving.${appDomain}/fundraisers`
      obj.keys.ga = ''
      break
    case environments.SANDBOX:
      obj.urls.api = `https://sandbox-api.${appDomain}/`
      obj.urls.app = `https://sandbox.${appDomain}`
      obj.urls.fundraiserUrl = `https://sandbox-giving.${appDomain}/fundraisers`
      obj.keys.ga = ''
      break
    case environments.STAGING:
      obj.urls.api = `https://staging-api.${appDomain}/`
      obj.urls.app = `https://staging-partners.${appDomain}`
      obj.urls.fundraiserUrl = `https://staging-giving.${appDomain}/fundraisers`
      obj.keys.ga = ''
      break
    case environments.PREPROD:
      obj.urls.api = `https://api.pre-production.${appDomain}/`
      obj.urls.app = `https://pre-production-partners.${appDomain}`
      obj.urls.fundraiserUrl = `https://pre-production-giving.${appDomain}/fundraisers`
      obj.keys.ga = ''
      break
    case environments.PRODUCTION:
    default:
      obj.urls.api = `https://api.${appDomain}/`
      obj.urls.app = `https://partners.${appDomain}`
      obj.urls.fundraiserUrl = `https://giving.${appDomain}/fundraisers`
      obj.keys.ga = ''
      break
  }

  return obj
})()

export const config = {
  ...envConfig,
  version: '1.0.0'
}
