import { Fundraiser } from '@percent/partner-dashboard/services/fundraisers/fundraisersService.types'
import { useState } from 'react'

export function useFundraisersActionModal() {
  const [selectedFundraiser, setSelectedFundraiser] = useState<Fundraiser | null>(null)
  const [isFundraiserModalOpen, setIsFundraiserModalOpen] = useState<boolean>(false)

  const openFundraiserModal = (fundraiser: Fundraiser) => {
    setSelectedFundraiser(fundraiser)
    setIsFundraiserModalOpen(true)
  }

  const closeFundraiserModal = () => {
    setIsFundraiserModalOpen(false)
  }

  return {
    selectedFundraiser,
    isFundraiserModalOpen,
    openFundraiserModal,
    closeFundraiserModal
  }
}
