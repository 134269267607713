import { useTranslation } from 'react-i18next'
import { ChangeEvent, EventHandler, useState, KeyboardEvent } from 'react'

import {
  Badge,
  Filter,
  ProgressBar,
  SearchInput,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow,
  Tooltip
} from '@percent/lemonade'
import { formatMoney } from '@percent/utility'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { FundraisersTableProps } from './FundraisersTable.types'
import { config } from '@percent/partner-dashboard/config'
import styles from './FundraisersTable.module.scss'
import { useStatusFilter } from '../hooks/useStatusFilter'
import { useFundraisersActionModal } from '../hooks/useFundraiserActionModal'
import { CloseFundriaserModal } from '../FundraisersModal/CloseFundraiserModal'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { FundraisersMenu } from '../FundraisersMenu/FundraisersMenu'
import { getFormattedDate } from '@percent/partner-dashboard/common/library/utility/date'

export function FundraisersTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  queryParams,
  setQueryParams,
  refresh
}: Readonly<FundraisersTableProps>) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()
  const { selectedFundraiser, isFundraiserModalOpen, openFundraiserModal, closeFundraiserModal } =
    useFundraisersActionModal()
  const { userCan } = usePermissionCheck()
  const [searchValue, setSearchValue] = useState<string>(queryParams?.query || '')

  const columns = [
    { id: t('table.header.name'), props: { width: '20%' } },
    { id: t('table.header.nonprofit'), props: { width: '20%' } },
    { id: t('table.header.owner') },
    { id: t('table.header.goal'), props: { width: '10%' } },
    { id: t('table.header.createdAt'), props: { width: '12%' } },
    { id: t('table.header.status'), props: { width: '6%' } },
    { id: t('table.header.actions'), props: { width: '4%' } }
  ]

  const { statusOptions, selectedStatus, onStatusChange } = useStatusFilter({ queryParams, setQueryParams })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (searchValue.length <= 255) {
      if (event.key === 'Enter') {
        setQueryParams?.({
          query: (event.target as HTMLInputElement).value.trim()
        })
      }
    }

    if (event.key === 'Escape') {
      setQueryParams?.({
        query: ''
      })
      return setSearchValue('')
    }

    return
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <TableProvider
        columns={columns}
        totalResults={totalResults}
        isLoading={isLoading || !data}
        illustration="no-results"
        emptyTableTitle={
          queryParams?.query
            ? t('typography.noSearchTableListTitle', {
                searchTerm: queryParams?.query
              })
            : t('typography.noListTitle', { listType: 'fundraisers' })
        }
        emptyTableDescription={
          queryParams?.query
            ? t('typography.noSearchTableListDescription')
            : t('typography.noListDescription', { listType: 'fundraisers' })
        }
      >
        <div className={styles.filterContainer}>
          <SearchInput
            name="search"
            value={searchValue}
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            handleClearValue={() => {
              setSearchValue('')
              setQueryParams?.({
                query: ''
              })
            }}
            placeholder={t('table.fundraisersListSearchPlaceholder')}
            status={searchValue.length >= 255 ? 'danger' : 'default'}
            statusMessage={t('errorMessage.shouldNotExceed255')}
          />
          <Filter
            label={t('table.status')}
            options={statusOptions}
            selectedValue={selectedStatus}
            onChange={onStatusChange}
            testId="fundraiser-status"
          />
        </div>
        <Table>
          <TableBody>
            {data?.map(fundraiser => {
              const fundraiserUrl = `${config.urls.fundraiserUrl}/${fundraiser.id}`
              return (
                <TableRow key={fundraiser.id}>
                  <TableCell>
                    <Tooltip content={fundraiser.page.title}>
                      <a
                        href={`${config.urls.fundraiserUrl}/${fundraiser.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.fundraisersNameCell}>{fundraiser.page.title}</span>
                      </a>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip content={fundraiser.organisation.name}>
                      <span className={styles.fundraisersTableCell}>{fundraiser.organisation.name}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip content={fundraiser.organiser.email}>
                      <div className={styles.donorWrapper}>
                        <span>{fundraiser.organiser.name}</span>
                        <span className={styles.email}>{fundraiser.organiser.email}</span>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={styles.fundraiserGoalCell}>
                    {formatMoney(fundraiser.donationTotal, { locale, hideDecimalsIfZero: true })}/
                    {formatMoney(fundraiser.campaign.goal, { locale, hideDecimalsIfZero: true })}
                    <ProgressBar
                      progressPercentage={Math.min(
                        (fundraiser.donationTotal.amount / fundraiser.campaign.goal.amount) * 100,
                        100
                      )}
                      backgroundColorComplete="info400"
                      backgroundColorIncomplete="info400"
                      shadeColor="gray90"
                      sizeVariant="x-small"
                    />
                  </TableCell>
                  <TableCell>{getFormattedDate(fundraiser.createdAt)}</TableCell>
                  <TableCell>
                    {fundraiser.status === 'published' ? (
                      <Badge variant="informative">{t('status.open')}</Badge>
                    ) : (
                      <Badge variant="default">{t(`status.${fundraiser.status}`)}</Badge>
                    )}
                  </TableCell>
                  <TableCell>
                    <FundraisersMenu
                      fundraiser={fundraiser}
                      fundraiserUrl={fundraiserUrl}
                      openFundraiserModal={openFundraiserModal}
                      userCan={userCan}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          totalResultsText={t('table.results', { count: totalResults || undefined })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </TableProvider>
      {isFundraiserModalOpen && selectedFundraiser && (
        <CloseFundriaserModal
          isOpen={isFundraiserModalOpen}
          onClose={closeFundraiserModal}
          fundraiser={selectedFundraiser}
          refresh={refresh}
        />
      )}
    </>
  )
}
