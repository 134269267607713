import { StatusType, ValidationSubmission } from '../../../types'

export const mapOrganisationStatus = (
  verification: ValidationSubmission
): StatusType.Pending | StatusType.Approved | StatusType.Rejected => {
  if (verification.validationRequest?.rejectedAt) {
    return StatusType.Rejected
  }

  if (verification?.organisationId || verification?.organisation?.id) {
    return StatusType.Approved
  }

  return StatusType.Pending
}
