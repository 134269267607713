export const formatMonitoringMessage = (
  organisationIsInactive: boolean,
  flaggedMonitoringKeys: string[] | undefined,
  t: (key: string) => string
) => {
  if (organisationIsInactive && flaggedMonitoringKeys?.length) {
    return `${t('typography.organisationIsInactiveAndFlagged')} ${flaggedMonitoringKeys
      .map(flag => t(`typography.${flag}`))
      .join(', ')}`
  }

  if (organisationIsInactive) {
    return t('typography.organisationIsInactive')
  }

  if (flaggedMonitoringKeys?.length) {
    return `${t('typography.flaggedFor')} ${flaggedMonitoringKeys.map(flag => t(`typography.${flag}`)).join(', ')}`
  }

  return null
}
