import { useTranslation } from 'react-i18next'
import { ChangeEvent, EventHandler, KeyboardEvent, useEffect, useMemo, useState } from 'react'

import { Filter, SearchInput, TableSearchAndFilters } from '@percent/lemonade'
import { SearchAndFiltersProps } from './SearchAndFilters.types'
import { OpportunityStatus } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'
import styles from '../VolunteeringTable.module.scss'

const statusMapping: Record<OpportunityStatus, string> = {
  [OpportunityStatus.ACTIVE]: 'Active',
  [OpportunityStatus.CLOSED]: 'Past',
  [OpportunityStatus.CANCELLED]: 'Cancelled'
}
export function SearchAndFilters({ queryParams, setQueryParams }: Readonly<SearchAndFiltersProps>) {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>(queryParams?.query || '')

  const statusesOptions = useMemo(
    () => [
      { label: t('status.all'), value: '' },
      { label: t('status.active'), value: OpportunityStatus.ACTIVE },
      { label: t('status.past'), value: OpportunityStatus.CLOSED },
      { label: t('status.cancelled'), value: OpportunityStatus.CANCELLED }
    ],
    [t]
  )

  const selectedStatus: OpportunityStatus = useMemo(() => {
    if (queryParams?.status?.length === Object.keys(OpportunityStatus).length) {
      return ''
    }

    return statusMapping[queryParams?.status?.[0]] || ''
  }, [queryParams?.status])

  useEffect(() => {
    setSearchValue(queryParams?.query || '')
  }, [queryParams])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (searchValue.length <= 255) {
      if (event.key === 'Enter') {
        setQueryParams?.({
          query: (event.target as HTMLInputElement).value.trim()
        })
      }
    }

    if (event.key === 'Escape') {
      setQueryParams?.({
        query: ''
      })
      return setSearchValue('')
    }

    return
  }

  const handleStatusFilterChange = selectedStatus => {
    if (!selectedStatus?.length) {
      setQueryParams?.({
        status: [OpportunityStatus.ACTIVE, OpportunityStatus.CLOSED, OpportunityStatus.CANCELLED]
      })
    } else {
      setQueryParams?.({
        status: [selectedStatus]
      })
    }
  }

  return (
    <TableSearchAndFilters>
      <div className={styles.searchAndFilters}>
        <SearchInput
          name="search"
          value={searchValue}
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          handleClearValue={() => {
            setSearchValue('')
            setQueryParams?.({
              query: null
            })
          }}
          placeholder={t('table.volunteeringSearchPlaceholder')}
          status={searchValue.length >= 255 ? 'danger' : 'default'}
          statusMessage={t('errorMessage.shouldNotExceed255')}
        />
        <Filter
          label={t('table.header.status')}
          options={statusesOptions}
          selectedValue={selectedStatus}
          onChange={handleStatusFilterChange}
          testId="opportunity-status-filter"
        />
      </div>
    </TableSearchAndFilters>
  )
}
