import { AlertVariant } from '@percent/lemonade'
import { StatusType } from '../../../types'

const alertProps = (): Record<string, { variant: AlertVariant }> => ({
  approved: {
    variant: 'success'
  },
  rejected: {
    variant: 'error'
  },
  pending: {
    variant: 'info'
  }
})

export const mapAlertProps = (status: StatusType.Approved | StatusType.Rejected | StatusType.Pending) =>
  alertProps()[status]
