import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { DonationContainer } from '../donation/DonationContainer'
import { Home } from '../home/Home'
import { Key } from '../key/Key'
import { NonprofitsContainer } from '../nonprofits/NonprofitsContainer'
import { CategoryContainer } from '../category/CategoryContainer'
import { CategoryNonprofitsTable } from '../category/categoryNonprofitsTable/CategoryNonprofitsTable'
import { MyAccount } from '../myAccount/MyAccount'
import { UsersContainer } from '../users/UsersContainer'
import { VerificationsContainer } from '../verifications/VerificatonsContainer'
import { VerificationDetailsContainer } from '../verifications/VerificationDetailsContainer'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { EmployeesContainer } from '../workplaceGiving/Employees/EmployeesContainer'
import { useAuthState } from '@percent/partner-dashboard/common/hooks'
import { FundraisersContainer } from '../workplaceGiving/Fundraisers/FundraisersContainer'
import { VolunteeringContainer } from '../workplaceGiving/Volunteering/VolunteeringContainer'
import { VolunteeringSessionsOpportunityContainer } from '../workplaceGiving/Volunteering/VolunteeringSessionsOpportunityContainer/VolunteeringSessionsOpportunityContainer'

export function Routes() {
  const { userCan } = usePermissionCheck()
  const { partner } = useAuthState()

  const hasWorkplaceGiving = partner?.scopes?.some(data => data.scopeName.includes('workplace_giving'))
  const hasFundraisers = partner?.scopes?.some(data => data.scopeName.includes('donation_link'))
  const hasVolunteering = partner?.scopes?.some(data => data.scopeName.includes('volunteering'))

  return (
    <Switch>
      <Route path="/my-account" exact component={MyAccount} />
      <Route path="/dashboard">
        <Home />
      </Route>
      <Route path="/keys">
        <Key />
      </Route>
      {userCan('viewDonations') && (
        <Route path="/donations">
          <DonationContainer />
        </Route>
      )}
      <Route path="/nonprofits">
        <NonprofitsContainer />
      </Route>
      <Route path="/users">
        <UsersContainer />
      </Route>
      <Route exact path="/categories" component={CategoryContainer} />
      <Route exact path="/categories/:categoryId" component={CategoryNonprofitsTable} />
      <Route exact path="/verifications" component={VerificationsContainer} />
      <Route exact path="/verifications/:verificationId" component={VerificationDetailsContainer} />
      <Route exact path="/applications">
        <Redirect to="/verifications" />
      </Route>
      {hasWorkplaceGiving && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route exact path="/workplace-giving/employees" component={EmployeesContainer} />
      )}
      {hasFundraisers && userCan('viewFundraisers') && (
        <Route exact path="/workplace-giving/fundraisers" component={FundraisersContainer} />
      )}
      {hasVolunteering && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route exact path="/workplace-giving/volunteering" component={VolunteeringContainer} />
      )}
      {hasVolunteering && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route
          exact
          path="/workplace-giving/volunteering/:seriesOpportunityId"
          component={VolunteeringSessionsOpportunityContainer}
        />
      )}
      {hasWorkplaceGiving && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route exact path="/workplace-giving">
          <Redirect to="/workplace-giving/employees" />
        </Route>
      )}
      <Redirect from="/applications/:applicationId" to="/verifications/:applicationId" />
      <Route path="*">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  )
}
