import { StatusType, Monitoring } from '../../../types'

export const mapComplainceStatus = (
  monitoring: Monitoring
): StatusType.Approved | StatusType.Rejected | StatusType.Pending => {
  if (monitoring?.status === 'live' && monitoring?.results?.complianceStatus === 'pass') {
    return StatusType.Approved
  }

  if (monitoring?.results?.complianceStatus === 'fail' || monitoring?.status === 'validation_request_failed') {
    return StatusType.Rejected
  }

  return StatusType.Pending
}
