import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { StringParam, useQueryParams } from 'use-query-params'
import { EmployeesTable } from './EmployeesTable/EmployeesTable'
import { useBreadcrumbsData } from '../../layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function EmployeesContainer() {
  const { workplaceGivingService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams(
    {
      filter: StringParam
    },
    {}
  )

  const [{ data, isLoading, errorMessage, totalResults }, { query, nextPage, previousPage, refresh }] = useQueryList(
    workplaceGivingService.getEmployeesList,
    queryParams
  )

  const { t } = useTranslation()
  const { setPath } = useBreadcrumbsData()

  useEffect(() => {
    setPath(t('menu.workplaceGivingSubmenu.employees'))
  }, [t, setPath])

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <EmployeesTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
