import { ButtonText } from '@percent/lemonade'

import { useTranslation } from 'react-i18next'

import Jira from '../../../../assets/icons/jira.svg'
import { CheckActionProps } from './CheckActions.types'
import styles from './CheckActions.module.scss'

export const CheckActions = ({ jiraLink, goToCheckLink, isGoToCheckDisabled = false }: CheckActionProps) => {
  const { t } = useTranslation()

  const openInNewTab = (url: string) => window.open(url, '_blank')

  if (!jiraLink && !goToCheckLink) return null

  return (
    <div className={styles.checkActionsWrapper} data-testid="checks-actions">
      {jiraLink && (
        <div className={styles.jiraButtonWrapper}>
          <img src={Jira} width={16} height={16} alt="Jira" />
          <ButtonText onPress={() => openInNewTab(jiraLink)}>{t('typography.jira')}</ButtonText>
        </div>
      )}
      {goToCheckLink && (
        <ButtonText icon="expand" onPress={() => openInNewTab(goToCheckLink)} disabled={isGoToCheckDisabled}>
          {t('typography.goToCheck')}
        </ButtonText>
      )}
    </div>
  )
}
