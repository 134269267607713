import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Modal, ActionModal, Text, Spacer, FormField, Select, AcknowledgeModal, Loader } from '@percent/lemonade'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useMutation, useQuery } from '@percent/partner-dashboard/common/hooks'
import { EmployeesModalProps } from './EmployeesModal.types'

export function AssignAchievementModal({ isOpen, onClose, employee, refresh }: EmployeesModalProps) {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const { t } = useTranslation()
  const { workplaceGivingService } = useServices()

  const [{ data, isLoading }, { query }] = useQuery(
    workplaceGivingService.getAccountAchievements,
    { accountId: employee.id },
    false,
    false
  )

  useEffect(() => {
    if (isOpen) {
      query({ accountId: employee.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const achievementsOptions = data?.rulesAndAchievements
    .filter(({ accountAchievement }) => !accountAchievement)
    .map(({ achievement }) => ({
      label: achievement.name,
      value: achievement.id
    }))

  const [{ isLoading: isAssignRequestLoading }, { apiFunc }] = useMutation(
    workplaceGivingService.assignAchievement,
    () => {
      onClose()
      setIsSuccess(true)
      refresh()
    },
    e => {
      onClose()
      setIsError(true)
    }
  )

  const handleCloseSuccessModal = () => {
    setIsSuccess(false)
    resetForm()
  }

  const handleCloseErrorModal = () => {
    setIsError(false)
    resetForm()
  }

  const { errors, touched, dirty, handleSubmit, isValid, setFieldValue, resetForm } = useFormik({
    initialValues: {
      achievementId: ''
    },
    validationSchema: Yup.object().shape({
      achievementId: Yup.string().required(t('errorMessage.required'))
    }),
    onSubmit: async ({ achievementId }) => {
      await apiFunc({
        accountId: employee.id,
        achievementId
      })
    }
  })

  const handleClose = () => {
    resetForm()
    onClose()
  }

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ActionModal
          title={t('dialog.employees.achievements.title')}
          primaryButtonText={t('button.assign')}
          secondaryButtonText={t('button.cancel')}
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && !isAssignRequestLoading && dirty)}
          loading={isAssignRequestLoading}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          primaryBtnTestId="send-request"
          secondaryBtnTestId="cancel-request"
          aria-labelledby="assign-achievement-modal"
        >
          {isLoading ? (
            <Loader />
          ) : data?.rulesAndAchievements?.length ? (
            <>
              <Text size="small">
                {t('dialog.employees.achievements.description', {
                  employeeName: employee.fullName,
                  employeeEmail: employee.email
                })}
              </Text>

              <Spacer size={4} axis="vertical" />

              <Text size="small">{t('dialog.employees.achievements.currentAchievementsInfo')}</Text>

              {data.rulesAndAchievements.filter(a => a.accountAchievement?.id).length ? (
                <ul style={{ margin: 0, padding: '0 0 0 25px' }}>
                  {data.rulesAndAchievements
                    .filter(a => a.accountAchievement?.id)
                    .map(({ achievement }) => (
                      <li key={achievement.id}>
                        <Text size="small">{achievement.name}</Text>
                      </li>
                    ))}
                </ul>
              ) : (
                <>
                  <Spacer size={2} axis="vertical" />
                  <Text size="small">{t('dialog.employees.achievements.noAchievementsYetInfo')}</Text>
                </>
              )}

              <form onSubmit={handleSubmit}>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.achievement')}
                  status={touched.achievementId && errors.achievementId ? 'danger' : 'default'}
                  statusMessage={errors.achievementId}
                  disabled={achievementsOptions?.length === 0}
                >
                  <Select
                    name="achievement"
                    placeholder={t('form.selectAchievement')}
                    onChange={option => {
                      setFieldValue('achievementId', option.value)
                    }}
                    options={achievementsOptions}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
              </form>
            </>
          ) : null}
        </ActionModal>
      </Modal>

      <Modal open={isSuccess} onClose={handleCloseSuccessModal} aria-labelledby="add-wallet-balance-success">
        <AcknowledgeModal
          result="positive"
          title={t('dialog.employees.achievements.success.title')}
          buttonText={t('button.close')}
          handleClose={handleCloseSuccessModal}
          buttonTestId="success-modal-btn"
        />
      </Modal>

      <Modal open={isError} onClose={handleCloseErrorModal} aria-labelledby="add-wallet-balance-error">
        <AcknowledgeModal
          result="negative"
          title={t('dialog.employees.achievements.error.title')}
          description={t('dialog.employees.achievements.error.description')}
          buttonText={t('button.close')}
          handleClose={handleCloseErrorModal}
          buttonTestId="error-modal-btn"
        />
      </Modal>
    </>
  )
}
