import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { styledVariant } from '../../util'
import { StyledTextInputProps, TextInputProps } from './text-input.types'
import { inputStatusStyles } from '../../shared/input-status'
import { Icon } from '../icon'

export const StyledTextInput = styled.input<StyledTextInputProps>`
  ${styledVariant('$status', inputStatusStyles)};

  ${({ theme, $withIcon }) =>
    `
    box-sizing: border-box;
    width: 100%;
    height: ${theme.sizes[10]};
    padding: ${
      $withIcon
        ? `0 ${theme.sizes[4]} 0 ${theme.sizes[8]}`
        : `0 ${theme.sizes[4]}`
    };
    border-radius: ${theme.borderRadii.medium};
    outline:none;

    font-size:  ${theme.fontSizes.bodySmall};
    color: ${theme.colors.black};

    ::placeholder {
      color: ${theme.colors.gray300};
    }

    &:disabled {
      background-color: ${theme.colors.gray80};
      outline: none;
      border: none;
    }

    &:read-only {
      cursor: not-allowed;
      background-color: ${theme.colors.gray80};
      border-color: ${theme.colors.gray80};

      &:hover {
        outline: none;
        background-color: ${theme.colors.gray80};
        border-color: ${theme.colors.gray80};
      }
    }

    @media only screen and (max-device-width: 600px) {
      -webkit-appearance: none;
      font-size: ${theme.sizes[4]};
    },
  `}
`

const StyledTextInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledIconWrapper = styled.span`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${theme.sizes[1]};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.sizes[8]};
    pointer-events: none;
  `}
`

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ status = 'default', icon, ...props }: TextInputProps, ref) => {
    return (
      <StyledTextInputWrapper>
        {icon ? (
          <StyledIconWrapper>
            <Icon name={icon} color="neutral300" size={6} />
          </StyledIconWrapper>
        ) : null}
        <StyledTextInput
          type="text"
          {...props}
          $status={status}
          $withIcon={!!icon}
          ref={ref}
        />
      </StyledTextInputWrapper>
    )
  }
)
