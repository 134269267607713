import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useMemo, useState } from 'react'

import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow,
  Tooltip
} from '@percent/lemonade'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { useFeatureFlag, useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { VolunteeringTableProps } from './VolunteeringTable.types'
import styles from './VolunteeringTable.module.scss'
import {
  CancelOpportunityErrorCode,
  OpportunityStatus,
  OpportunityType
} from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'
import { getFormattedDate } from '@percent/partner-dashboard/common/library/utility/date'

import { VolunteeringActionsMenu } from '../VolunteeringActionsMenu/VolunteeringActionsMenu'
import { CancelOpportunityModal } from '../CancelOpportunity/Modal/CancelOpportunityModal'
import { CancelOpportunityErrorModal } from '../CancelOpportunity/ErrorModal/CancelOpportunityErrorModal'
import { SearchAndFilters } from './SearchAndFilters/SearchAndFilters'
import { CancelOpportunitySuccessModal } from '../CancelOpportunity/SuccessModal/CancelOpportunitySuccessModal'

export function VolunteeringTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  queryParams,
  setQueryParams,
  refresh
}: Readonly<VolunteeringTableProps>) {
  const { t } = useTranslation()
  const history = useHistory()
  const { workplaceGivingService } = useServices()
  const { featuredVolunteeringFlag } = useFeatureFlag()

  const [selectedOpportunity, setSelectedOpportunity] = useState<{ id: string; name: string } | undefined>()
  const [cancelErrorModalOpen, setCancelErrorModalOpen] = useState(false)
  const [cancelSuccessModalOpen, setCancelSuccessModalOpen] = useState(false)
  const [errorCode, setErrorCode] = useState<CancelOpportunityErrorCode | undefined>(undefined)

  const [{ isLoading: isCancelLoading, error }, { apiFunc: cancelOpportunity }] = useMutation(
    workplaceGivingService.cancelVolunteeringOpportunity,
    res => {
      if (res.data?.error) {
        setCancelErrorModalOpen(true)
        setErrorCode(res.data?.error?.code)
      } else {
        setCancelSuccessModalOpen(true)
        refresh()
      }
    },
    error => {
      if (error) {
        setCancelErrorModalOpen(true)
      }
    }
  )

  const handleCancelOpportunity = () => {
    if (selectedOpportunity?.id) {
      setErrorCode(undefined)
      cancelOpportunity(selectedOpportunity.id)
      setSelectedOpportunity(undefined)
    }
  }

  const columns = useMemo(
    () => [
      { id: t('table.header.name'), props: { width: '25.5%' } },
      { id: t('table.header.nonprofit'), props: { width: '12%' } },
      { id: t('table.header.owner'), props: { width: '22%' } },
      { id: t('table.header.attendees'), props: { width: '13%' } },
      { id: t('table.header.createdAt'), props: { width: '14%' } },
      { id: t('table.header.status'), props: { width: '9.5%' } },
      { id: t('table.header.actions'), props: { width: '5%' } }
    ],
    [t]
  )

  const getStatusBadge = (status: OpportunityStatus) => {
    switch (status) {
      case OpportunityStatus.ACTIVE:
        return <Badge variant="informative">{t('status.active')}</Badge>
      case OpportunityStatus.CLOSED:
        return <Badge variant="informative">{t('status.past')}</Badge>
      case OpportunityStatus.CANCELLED:
        return <Badge variant="informative">{t('status.cancelled')}</Badge>
    }
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <TableProvider
        columns={columns}
        totalResults={totalResults}
        isLoading={isLoading || !data}
        illustration="no-results"
        emptyTableTitle={
          queryParams?.query?.length || queryParams?.status?.length
            ? t('typography.noSearchVolunteeringOpportunitiesTitle')
            : t('typography.noVolunteeringOpportunitiesTitle')
        }
        emptyTableDescription={
          queryParams?.query?.length || queryParams?.status?.length
            ? t('typography.noSearchVolunteeringOpportunitiesDescription')
            : t('typography.noVolunteeringOpportunitiesDescription')
        }
      >
        <SearchAndFilters queryParams={queryParams} setQueryParams={setQueryParams} />
        <Table>
          <TableBody>
            {data?.map(opportunity => {
              const opportunityHref = `${process.env.NX_WPG_ADDRESS}/volunteer/opportunities/${opportunity.id}`
              const OpportunityLink = (
                <a className={styles.link} href={opportunityHref} target="_blank" rel="noopener noreferrer">
                  {opportunity.name.length > 38 ? `${opportunity.name.substring(0, 35)}...` : opportunity.name}
                </a>
              )

              return (
                <TableRow key={opportunity.id}>
                  <TableCell>
                    {opportunity.name.length > 38 ? (
                      <Tooltip content={opportunity.name}>{OpportunityLink}</Tooltip>
                    ) : (
                      OpportunityLink
                    )}
                  </TableCell>
                  <TableCell>{opportunity.organisation?.name || t('status.notAvailable')}</TableCell>
                  <TableCell>
                    {opportunity.createdBy?.email ? (
                      <Tooltip content={opportunity.createdBy?.email}>
                        <div className={styles.ownerWrapper}>
                          <span>{opportunity.createdBy.fullName}</span>
                          <span className={styles.email}>{opportunity.createdBy.email}</span>
                        </div>
                      </Tooltip>
                    ) : (
                      t('status.notAvailable')
                    )}
                  </TableCell>
                  <TableCell>
                    {opportunity.type === OpportunityType.EVENT_SERIES ? (
                      <Link className={styles.link} to={`/workplace-giving/volunteering/${opportunity.id}`}>
                        {t('volunteering.multipleSessions')}
                      </Link>
                    ) : (
                      `${opportunity.participants.count}${
                        opportunity.participants.spots ? `/${opportunity.participants.spots}` : ''
                      }`
                    )}
                  </TableCell>
                  <TableCell>{getFormattedDate(opportunity.createdAt)}</TableCell>
                  <TableCell>{getStatusBadge(opportunity.status)}</TableCell>
                  <TableCell>
                    <VolunteeringActionsMenu
                      items={[
                        {
                          key: 'view',
                          label: t('button.view')
                        },
                        ...(opportunity.type === OpportunityType.EVENT_SERIES
                          ? [
                              {
                                key: 'seeSessions',
                                label: t('button.seeSessions')
                              }
                            ]
                          : []),
                        ...(opportunity.status === OpportunityStatus.ACTIVE
                          ? [
                              {
                                key: 'cancel',
                                label: t('button.cancel')
                              }
                            ]
                          : []),
                        ...(featuredVolunteeringFlag && opportunity.status === OpportunityStatus.ACTIVE
                          ? [
                              {
                                key: 'addFeatured',
                                label: 'Feature this opportunity'
                              }
                            ]
                          : [])
                      ]}
                      handleSelect={(actionKey: string) => {
                        switch (actionKey) {
                          case 'view':
                            window.open(opportunityHref, '_blank', 'noopener noreferrer')
                            break
                          case 'seeSessions':
                            history.push(`/workplace-giving/volunteering/${opportunity.id}`)
                            break
                          case 'cancel':
                            setSelectedOpportunity({
                              id: opportunity.id,
                              name: opportunity.name
                            })
                            break
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          totalResultsText={t('table.results', { count: totalResults || undefined })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </TableProvider>
      {selectedOpportunity ? (
        <CancelOpportunityModal
          open={!!selectedOpportunity}
          opportunityName={selectedOpportunity.name}
          handleCancelOpportunity={handleCancelOpportunity}
          handleClose={() => {
            setSelectedOpportunity(undefined)
          }}
          isLoading={isCancelLoading}
        />
      ) : null}
      <CancelOpportunitySuccessModal
        open={cancelSuccessModalOpen}
        handleClose={() => {
          setCancelSuccessModalOpen(false)
        }}
      />
      {error || errorCode ? (
        <CancelOpportunityErrorModal
          open={cancelErrorModalOpen}
          handleClose={() => {
            setCancelErrorModalOpen(false)
          }}
          errorCode={(error?.code as CancelOpportunityErrorCode) || errorCode}
        />
      ) : null}
    </>
  )
}
