import { Menu } from '@percent/lemonade'
import { FundraisersMenuProps } from './FundraisersMenu.types'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@percent/partner-dashboard/common/hooks'

export const FundraisersMenu = ({ fundraiser, fundraiserUrl, openFundraiserModal, userCan }: FundraisersMenuProps) => {
  const { t } = useTranslation()
  const { featuredFundraiserFlag } = useFeatureFlag()

  const menuItems = [
    { key: 'view', label: t('dialog.fundraisers.view') },
    ...(userCan('manageFundraisers')
      ? [
          {
            key: 'close',
            label: t('dialog.fundraisers.close'),
            shouldBeDisabled: fundraiser.status === 'closed'
          }
        ]
      : [])
  ]

  const handleMenuSelect = (actionKey: string) => {
    switch (actionKey) {
      case 'view':
        window.open(fundraiserUrl, '_blank', 'noopener noreferrer')
        break
      case 'close':
        openFundraiserModal(fundraiser)
        break
      default:
        console.warn(`Unhandled action: ${actionKey}`)
    }
  }

  return (
    <Menu
      title={t('button.more')}
      sections={[
        {
          items: menuItems
        }
      ]}
      onSelect={handleMenuSelect}
    />
  )
}
