import { baseUrl } from '@percent/partner-dashboard/services/apiPaths'
import {
  PBPGetResponse,
  PBPListResponse,
  PBPResponse,
  PBPResponseWithError
} from '@percent/partner-dashboard/services/types/pbpResponses.types'
import {
  AddWalletBalanceProps,
  ConditionStateAndConditionAccountLimit,
  DonationWallet,
  Employee,
  GetEmployeesListProps,
  GetVolunteeringOpportunitiesListProps,
  UpdateMatchingBudgetProps,
  VolunteeringOpportunity,
  VolunteeringOpportunityDetails,
  WorkplaceGivingServiceCapabilities,
  CancelOpportunityErrorCode,
  AssignAchievementProps,
  Achievement
} from './workplaceGivingService.types'

export const workplaceGivingService = ({ percentClient }: WorkplaceGivingServiceCapabilities) => ({
  getEmployeesList: (params: GetEmployeesListProps) => {
    const { filter, ...otherParams } = params

    return percentClient.get<PBPListResponse<Employee>>(`${baseUrl}partner/giving/employees`, {
      params: {
        ...otherParams,
        ...(filter && { filter })
      }
    })
  },
  addWalletBalance: ({ amount, accountId }: AddWalletBalanceProps) => {
    return percentClient.post<PBPResponse<DonationWallet>>(`v1/accounts/${accountId}/donation-wallet/add`, { amount })
  },
  updateMatchingBudget: ({ accountId, total }: UpdateMatchingBudgetProps) => {
    return percentClient.put<PBPResponse<ConditionStateAndConditionAccountLimit>>(
      `v1/accounts/${accountId}/donation-matching/account-limit`,
      {
        total
      }
    )
  },
  getVolunteeringOpportunitiesList: (params: GetVolunteeringOpportunitiesListProps) => {
    const { query, parentId, ...otherParams } = params

    return percentClient.get<PBPListResponse<VolunteeringOpportunity>>(`${baseUrl}partner/volunteering/opportunities`, {
      params: {
        ...otherParams,
        ...(parentId && { parentId }),
        ...(query && { query })
      }
    })
  },
  getVolunteeringOpportunityDetails: (opportunityId: string) => {
    return percentClient.get<PBPResponse<VolunteeringOpportunityDetails>>(
      `${baseUrl}opportunities/${opportunityId}/details`
    )
  },
  cancelVolunteeringOpportunity: (opportunityId: string) => {
    return percentClient.post<
      PBPResponseWithError<
        { success?: boolean; error?: { code: CancelOpportunityErrorCode } },
        { code: CancelOpportunityErrorCode }
      >
    >(
      `${baseUrl}partner/volunteering/opportunities/${opportunityId}/cancel`,
      {},
      { validateStatus: status => [200, 400].includes(status) }
    )
  },
  getAccountAchievements: ({ accountId }: { accountId: string }) => {
    return percentClient.get<
      PBPGetResponse<{
        rulesAndAchievements: {
          accountAchievement: {
            id: string
          } | null
          achievement: Achievement
        }[]
      }>
    >(`v1/accounts/${accountId}/achievements`)
  },
  assignAchievement: ({ accountId, achievementId }: AssignAchievementProps) => {
    return percentClient.post(`v1/accounts/${accountId}/achievements`, { achievementId })
  },
  getAchievements: () => {
    return percentClient.get<PBPListResponse<Omit<Achievement, 'achievementId'>>>(`v1/achievements`)
  }
})
