const abilityKeys = [
  'viewApiKeys',
  'manageOwners',
  'manageAdmins',
  'manageDevelopers',
  'manageMembers',
  'viewDashboard',
  'viewDonations',
  'viewFundraisers',
  'manageFundraisers'
] as const
export type AbilityKey = typeof abilityKeys[number]
export type DefaultRoleId = 'pd:member' | 'pd:developer' | 'pd:admin' | 'pd:owner'

const permissions = {
  // IAM Domain
  user_role_owner: ['write'],
  user_role_admin: ['write'],
  user_role_developer: ['write'],
  user_role_member: ['write'],
  users: ['read'],
  user_invite: ['write'],
  api_keys: ['read'],

  // Donation Domain
  donations: ['read'],

  // Validation Domain
  validation_submissions: ['read'],
  validation_requests: ['read'],
  agent_verifications: ['read'],

  // Disbursement Domain

  // Organization Domain
  pd_dashboard: ['access'],

  // Partner Fundraisers
  partner_fundraiser: ['read', 'write']
} as const

type PermissionString<T extends Record<string, readonly string[]>, K extends keyof T = keyof T> = K extends string
  ? `${K}:${T[K][number]}`
  : never
export type Permission = PermissionString<typeof permissions>

export const abilityDefinitions: Record<AbilityKey, Permission[]> = {
  viewApiKeys: ['api_keys:read'],
  manageOwners: ['user_role_owner:write'],
  manageAdmins: ['user_role_admin:write'],
  manageDevelopers: ['user_role_developer:write'],
  manageMembers: ['user_role_member:write'],
  viewDashboard: ['pd_dashboard:access'],
  viewDonations: ['donations:read'],
  viewFundraisers: ['partner_fundraiser:read'],
  manageFundraisers: ['partner_fundraiser:write']
}
