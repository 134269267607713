import styled, { css } from 'styled-components'
import { ChipProps } from './chip.types'
import { Icon } from '../icon'

const ChipContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.sizes.xxs};
    width: fit-content;
    padding: ${theme.sizes.xxs} ${theme.sizes.xs};
    outline: 1px solid ${theme.colors.gray400};
    border-radius: ${theme.sizes.xxs};
    border: 1px solid ${theme.colors.gray400};
    background: ${theme.colors.gray70};
  `}
`

const ChipLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray700};
    font-style: normal;
    font-size: ${theme.fontSizes.bodySmall};
    line-height: ${theme.sizes[6]};
  `}
`

const ChipValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray700};
    font-size: ${theme.fontSizes.bodySmall};
    font-style: normal;
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.sizes[6]};
  `}
`

const ChipButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    width: fit-content;
    padding: ${theme.sizes[3]} 0;
    height: ${theme.sizes[3]};
  `}
`

export const Chip = ({
  label,
  value,
  onClick,
  testId,
  icon = 'close',
}: ChipProps) => {
  return (
    <ChipContainer>
      {label && <ChipLabel>{label}:</ChipLabel>}
      {value && <ChipValue>{value}</ChipValue>}
      {onClick && (
        <ChipButton onClick={onClick} data-testid={`${testId}-button`}>
          <Icon name={icon} size={3} color="gray700" />
        </ChipButton>
      )}
    </ChipContainer>
  )
}
